import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllOrdersAsync } from "../../store/slices/orderSlice";

import withToast from "../../hocs/withToast";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import Loader from "../../components/Loader";


import { BASE_URL } from "../../api/helper";
import { Col, Row } from "react-bootstrap";

const columns = [
  { id: "name", label: "Order By", minWidth: 30, maxWidth: 50, align: "left" },
  { id: "email", label: "Email", minWidth: 110, align: "left" },
  { id: "address", label: "Address", minWidth: 150, align: "left" },
  { id: "createdAt", label: "Order Date", minWidth: 50, align: "left" },
  { id: "action", label: "Action", minWidth: 50, align: "left" }
];

const OrderManagement = ({ notify }) => {
  const dispatch = useDispatch();
  const {
    orders = [],
    totalCount,
    loading,
  } = useSelector((state) => state.orders);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(
      fetchAllOrdersAsync({
        value: filterValue,
        page,
        pageSize,
      })
    );
  }, [filterValue, page, pageSize, lastListLoadTime]);


  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const rowsWithAction = (rows) => {
    return rows?.orders?.map((e) => ({
      ...e,
      action: <span className="text-primary">More Details</span>,
      address: <span>{e.street}, {e.city}, {e.state}, {e.country}</span>,
      name: <span>{e.full_name}</span>,
      createdAt: <span>{e.createdAt}</span>,
      createdAt: <span>{new Date(e.ordered_date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })}</span>,
      callapseElement: (
      <div className="w-75">
        Ordered Items-
        <Row className="m-1">
          <Col md={4} >
            <strong>image</strong>
          </Col>
          <Col md={4}>
            <stong>Name</stong>
          </Col>
          <Col md={4}>
            <strong>Quantity</strong>
          </Col>
        </Row>
        {e?.cart_items?.map(ele => (
          <Row className="m-1">
            <Col md={4}>
              {e?.cart_items[0]?.product_image?.length ?
                <img
                  src={BASE_URL + "/doc/i/" + e?.cart_items[0]?.product_image[0]?.image_path}
                  height={50}
                  style={{ width: "100px" }}
                />
                : <></>
              }
            </Col>
            <Col md={4} className="d-flex align-items-center">
              {ele?.product_name}
            </Col>
            <Col md={4} className="d-flex align-items-center">
              {ele?.quantity}
            </Col>
          </Row>
        ))}
      </div>
      )
    }));
  };

  return (
    <div>
      <h3>Orders</h3>
      <div>
        <GenericFilter
          searchCb={(e) => setFilterValue(e.target.value)}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(orders)}
        rowCount={totalCount}
        cb={updatePageSetings}
        collapsibleRows
      />
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(OrderManagement);
