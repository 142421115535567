import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse"; // Import Collapse component
import { Col, Row } from "react-bootstrap";

// Custom styled TableCell and TableRow
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    transform: 'scale(0.99)',
    overflowX: 'hidden',
    transition: 'transform 0.2s ease',
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function GenericTable({
  columns,
  rows,
  maxHeight = 440,
  rowCount,
  cb,
  addCb,
  collapsibleRows
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null); // State to track the expanded row

  useEffect(() => {
    cb(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (rowIndex) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Fab
        color="secondary"
        aria-label="add"
        className="d-block d-md-none"
        onClick={addCb}
        sx={{ position: "absolute", bottom: 50, right: 0 }}
      >
        <AddIcon />
      </Fab>
      <TableContainer sx={{ maxHeight, minHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell
                  key={`col_idx_${index}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, rowIndex) => {
              return (
                <React.Fragment key={`row_idx_${rowIndex}`}>
                  {/* Main row */}
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    style={{ border: '10px solid green' }}
                  >
                    {columns.map((column) => {
                      console.log(row)
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} onClick={() => collapsibleRows && column.id === 'action' && row?.action?.props?.children === 'More Details' && handleRowClick(rowIndex)}>
                          {typeof value === "function" ||
                            React.isValidElement(value)
                            ? value
                            : column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>

                  {/* Collapsible row content */}
                  <TableRow>
                    <TableCell colSpan={columns.length} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={expandedRow === rowIndex} timeout="auto" unmountOnExit>
                          {row.callapseElement}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="align-pagination"
      />
    </Paper>
  );
}
